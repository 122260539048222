/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Result, Button, Icon } from 'antd';
import { ResultType, ResultProps } from 'antd/lib/result';
import { navigate } from '@reach/router';
import Page from '../components/Page/Page';
import IndexLayout from '../layouts';
import { RootModel } from '../redux/store';
import { ClearOrderActionCreator } from '../redux/order/order.actions';
import { useTranslation } from 'react-i18next';

export enum OrderStatuses {
  received = 'Received',
  confirmed = 'Confirmed',
  paid = 'Paid',
}

const SuccessPage = () => {
  const dispatch = useDispatch();
  const { t }= useTranslation();
  const { loading, order } = useSelector((state: RootModel) => ({
    loading: state.order.status.loading,
    order: state.order.order,
  }));

  const getStatus = (): ResultProps => {
    if (order && order.status) {
      switch (order.status.status) {
        case OrderStatuses.received:
          return {
            title: t('shell:success.message.received'),
            icon: <Icon type="smile" style={{ color: '#e66144' }} />,
            extra: loading && <Icon type="loading" style={{ color: '#e66144' }} />,
          };
        case OrderStatuses.confirmed:
          return {
            title: t('shell:success.message.confirmed'),
            icon: <Icon type="audit" style={{ color: 'cadetblue' }} />,
            extra: loading && <Icon type="loading" style={{ color: 'cadetblue' }} />,
          };
        case OrderStatuses.paid:
          return {
            title: t('shell:success.message.paid'),
            icon: <Icon type="smile" style={{ color: 'limegreen' }} />,
            extra: (
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  dispatch(ClearOrderActionCreator());
                  navigate('/');
                }}
              >
                {t('shell:success.button.label')}
              </Button>
            ),
          };
        default:
          return {
            title: t('shell:success.message.processing'),
          };
      }
    }
    return {
      title: t('shell:success.message.processing'),
    };
  };

  const resultProps: ResultProps = getStatus();

  return (
    <IndexLayout>
      <Page>
        <Result {...resultProps} />
      </Page>
    </IndexLayout>
  );
};

export default SuccessPage;
